@import '../variables';

.clientmessage {
    margin: auto;
    background-color: $color-clientmsg-info;
    max-width: $content-max-width;
    text-align: left;

    display: flex;
    padding: 0.5rem;

    p {
        margin: 0;
    }

    &-icon {
        padding-right: 0.5rem;
    }

    &-warning {
        background-color: $color-clientmsg-warning;
    }
}