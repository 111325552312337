.detail-selector {
  display: flex;
  align-items: center;
  width: 100%;

  .p-autocomplete {
    width: 55%;
    margin-right: .5rem;
  }

  .detail-selector-info {
    background-color: $background-color-selector;
    border-radius: .3125rem;

    div {
      margin: 0 .5rem;
      line-height: 34px;
      color: #333;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-selectable {
      cursor: pointer;
      &:hover {
        background-color: $color-secondary-hover;
      }
    }
  }

  .p-button {
    margin-left: .5rem;
    background: none;
    color: $color-primary;
  }
}