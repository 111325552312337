.terminal-session {
    margin-bottom: 1.5rem;
    position: relative;
}

.terminal-row {
    background-color: $color-background-gray;
    display: flex;
    flex-wrap: wrap;
}

.container-row {
    background-color: $color-background-light-gray;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.0625rem;
    position: relative;
}

.column {
    width: 20%;
    line-height: 2rem;
    display: flex;

    .column-title {
        padding-right: 1rem;
        text-align: right;
        width: calc(50% - 1rem);
    }

    .column-value {
        text-align: left;
        vertical-align: middle;
        width: 50%;
    }
}

.marks {
    width: 100%;
    display: flex;
    font-size: .8rem;
    color: $text-color-dark-gray;

    .mark-title {
        padding-right: 1rem;
        line-height: 2rem;
        text-align: right;
        width: 12.5%;
    }

    .mark-values {
        width: 100%;
        padding: .3rem 0;

        .mark-value {
            display: flex;
            text-align: left;
            margin: .3rem 0;
            word-break: break-word;
            
            p {
                margin: 0 0 0 .5rem;
            }
        }
    }
}

.status-icon {

    svg {
        width: 1rem;
        height: 1rem;
        padding: .35rem;
        border-radius: .85rem;
        vertical-align: middle;
    }
}

@media screen and (max-width: 1024px) {

    .column {
        width: 50%;

        .column-title {
            padding-left: 1rem;
            text-align: left;
        }
    }

}

@media screen and (max-width: 425px) {

    .column {
        width: 100%;

        .column-title {
            width: 50%;
        }
    }

    .marks {
        display: block;
        padding: 0 1rem;
    }

}