.nav-back-next-btns button {
    margin: 0.2rem;

    span {
        text-align: center;
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 425px) {

    .floating-btn {
        width: 100%;
        position: fixed;
        bottom: 0;
        background-color: $color-background-transparent-light;
        padding: 1rem;
        z-index: $z-index-floating-btn;
        display: flex;
        flex-direction: row;
    }

}