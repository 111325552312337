.login-background {
    background-color: $color-background-light-gray;
    line-height: $content-min-height;
}

.login-card-view {
    border-radius: 0.9rem;
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    background-color: $color-background-light;
    max-width: 20rem;
    padding: 1.5rem 1rem;
    color: $color-primary;
}

.trucker-card {
    margin-bottom: 1rem;
}

.trucker-card>img {
    width: 100%;
}

.login-card-view .input-row {
    text-align: left;
    padding: 0.5rem 0;
}

.login-card-view input {
    @include primary-input;
}

.input-button-row {
    text-align: center;
    padding-top: 1rem;
}

.login-button {
    @include primary-btn;
}

.additional-login-button {
    @include secondary-btn;
}

.impala-login-button {
    @include impala-btn;
}

.language-button {
    @include underline-btn;
}

.p-password {
    display: block;
}

@media screen and (max-width: 425px) {

    .login-background {
        background-color: $color-background-light;
    }

    .login-card-view {
        vertical-align: top;
        padding: 1.5rem 1rem;
    }

}