// color
$color-primary: #003476;
$color-secundary: #005cd5;
$color-secundary-hover: #0c76ff;
$text-color-light: #fff;
$text-color-dark: #222;
$text-color-dark-gray: #444;
$text-color-gray: rgb(73, 73, 73);
$text-color-error: rgba(184, 36, 36, .9);
$text-color-warning: rgba(212, 126, 6, 0.9);
$color-background-light: #fff;
$color-background-gray: #bbb;
$color-background-light-gray: #ddd;
$color-background-transparent: rgba(0, 0, 0, 0.5);
$color-background-transparent-light: #ffffffcc;
$color-boarder-primary: #ccc;
$color-custom-yellow-50: #fff2e2;
$color-custom-yellow-700: #cc8925;
$color-clientmsg-info: rgb(138, 207, 247);
$color-clientmsg-warning: rgb(234, 156, 156);
$color-impala-primary: #fdd065;
$color-impala-dark:  #c78e00;
$color-impala-text: black;
$background-color-selector: #ddd;
$color-secondary-hover: #0c76ff;

// z-index
$z-index-floating-btn: 10;
$z-index-overlayer: 11;
$z-index-errorstatus: 12;

//breakpoints 
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 512px;

// Loader
$loader: #0056c6;

// font
$font-family: "Open Sans",
    Arial,
    "Helvetica Neue",
    Helvetica,
    sans-serif;

// layout
$padding: 1.5rem;
$content-min-height: calc(100vh - 4.4375rem);
$content-max-width: 1440px;
$border-radius: 0.3125rem;

// mixin
@mixin primary-btn {
    width: 100%;
    background-color: $color-primary;
    color: $text-color-light;
    border: 0.0625rem solid $color-primary;
    border-radius: $border-radius;
}

@mixin secondary-btn {
    width: 100%;
    background-color: $color-background-light;
    color: $color-primary;
    border: 0.0625rem solid $color-primary;
    border-radius: $border-radius;
}

@mixin impala-btn {
    width: 100%;
    background-color: $color-impala-primary;
    color: $color-impala-text;
    border: 0.0625rem solid $color-impala-primary;
    border-radius: $border-radius;

    &:hover {
        color: $color-impala-text !important;
        background: $color-impala-dark !important;
        border-color: $color-impala-dark !important;
    }
}

@mixin underline-btn {
    text-decoration: underline;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
}

@mixin primary-input {
    width: 100%;
    border: 0.0625rem solid $color-primary;
    border-radius: $border-radius;
    height: 2rem;
    text-align: left;
    padding: 0 0.5rem;
}

@mixin error-msg {
    width: 15.8rem;
    padding-top: 0.1875rem;
    color: $text-color-error;
    font-size: 0.875rem;
}

@mixin spiner($size) {
    border: 0.3125rem solid #f3f3f3;
    border-radius: 50%;
    border-top: 0.3125rem solid $color-primary;
    width: $size;
    height: $size;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

@mixin cross($size: 1.5rem, $color: currentColor, $thickness: 0.0625rem) {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    position: relative;
    width: $size;
    height: $size;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: calc(($size - $thickness) / 2);
        left: 0;
        right: 0;
        height: $thickness;
        background: $color;
        border-radius: $thickness;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    span {
        display: block;
    }

}

@mixin custom-formbox-field {

    ul {
        text-align: left;
        padding-inline-start: 0;

        div {
            margin: .3rem 0;

            .p-checkbox {
                margin-right: .3rem;
            }
        }
    }
}

// transform
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}