.ampel {
    &-background {
        fill: #b2b2b2;

        &-disabeld{
        fill: #e9e9ed;
        stroke: #a4a4a4;
        stroke-miterlimit: 10;
        stroke-width: 2%;
        }
    }

    &-background,
    &-kritisch,
    &-voll,
    &-ok,
    &-gesperrt,
    &-disabeld{
        stroke: #1d1d1b;
        stroke-miterlimit: 10;
        stroke-width: 2%;
    }

    &-kritisch {
        fill: #efc90d;
    }

    &-voll {
        fill: #e71010;
    }

    &-ok {
        fill: #10e62d;
    }

    &-gesperrt {
        fill: #808080;
    }

    &-disabeld{
        fill: #8a8a8a;
    }

    &-dimmed {
        opacity: 50%;
    }
}