// Font
//@import 'https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300&family=Roboto+Condensed&display=swap';
@import 'theme/googlefonts';
// Modules
@import "~primeicons/primeicons";
@import "~primereact/resources/primereact.min.css";
//@import "~primereact/resources/themes/saga-blue/theme.css";

//Theme
@import 'theme/theme';

// Components
@import 'variables';
@import 'header.scss';
@import 'footer.scss';
@import 'language.scss';
@import 'login.scss';
@import 'tourenplan.scss';
@import 'terminalview';
@import 'errorstatus';
@import 'ctrshow';
@import 'ctrfullempty';
@import 'terminview';
@import 'booking';
@import 'errorview';

// Subcomponents
@import 'subcomponents-scss/terminalviewsingleterminal';
@import 'subcomponents-scss/buttongroup';
@import 'subcomponents-scss/selectionheaderview';
@import 'subcomponents-scss/formbox';
@import 'subcomponents-scss/cutomtoaststyle';
@import 'subcomponents-scss/navbacknextbtns';
@import 'subcomponents-scss/clientmessage';
@import 'subcomponents-scss/ampel';
@import 'subcomponents-scss/customdialog';
@import 'subcomponents-scss/loader';
@import 'subcomponents-scss/autoupdater';
@import 'subcomponents-scss/detailselector';

.App {
    text-align: center;
}

.content {
    margin: auto;
    max-width: $content-max-width;
}

.title {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: $color-primary;
    padding: .25rem;
}

.inputnumber-right {
    text-align: right;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-background-transparent;
    z-index: $z-index-overlayer;
}

.close-btn-fa {
    position: absolute;
    top: 0.3125rem;
    right: 0.3125rem;
}

.rebook-btn-fa {
    margin-top: 0.2rem;
}

.close-btn {
    @include cross(1.25rem, #fff, 0.125rem);
    position: absolute;
    top: 0.3125rem;
    right: 0.3125rem;
}

.close-btn-dark {
    @include cross(1.25rem, #000, 0.125rem);
    position: absolute;
    top: 0.3125rem;
    right: 0.3125rem;
}

.p-button:focus {
    box-shadow: 0 0 0 2px #000;
}

.primary-btn {
    @include primary-btn;
    max-width: 12.5rem;
}

.secondary-btn {
    @include secondary-btn;
    max-width: 12.5rem;
}

.underline-btn {
    @include underline-btn;
    color: $color-primary;
    margin: 1rem 0;
    font-weight: 500;
}

.space-16 {
    padding: 1rem;
}

.p-toast {
    opacity: 1;
}

.p-dropdown-panel {
    max-width: 425px;
}

.display-none {
    display: none;
}


.version-info {
    color: #999999;
    font-size: 0.7rem;
}

.version-info-container {
    text-align: right;
    padding-right: 0.9rem;

    &-mobile {
        display: none;
    }
}

@media screen and (max-width: 425px) {

    .content {
        padding-bottom: 5rem;
    }

    .title {
        font-size: 1.7rem;
    }

    .p-toast {
        position: fixed;
        width: 100%;
    }

    .p-toast-bottom-right {
        bottom: 1.25rem;
        right: 0;
        padding: 0.625rem;
    }

    .primary-btn {
        max-width: 23.5rem;
        width: calc(100% - 1rem);
    }

    .secondary-btn {
        max-width: 23.5rem;
        width: calc(100% - 1rem);
    }

    .p-dropdown-panel {
        max-width: 100%;
    }


    .version-info-container {
        display: none;

        &-mobile {
            display: block;
            text-align: center;
            position: fixed;
            bottom: .1rem;
            width: 100%;
            z-index: 500;
        }
    }


}