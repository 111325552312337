.terminview {
	margin: auto;
	max-width: 768px;

	.terminview-options-row {
		margin: 1rem 0;
		display: flex;

		span {
			min-width: 33.333%;
			text-align: right;
			align-self: center;
			padding: 0 0.25rem;

			input {
				text-align: center;
				width: 100%;
			}

			span {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}

	.terminview-options-row-buttons {
		span {
			width: 33%;
			text-align: center;

			button {
				width: 100%;
				max-width: 12rem;
				font-size: 1rem;
				padding: 0.5rem;
				margin: 0.2rem auto;

				span {
					text-align: center;
					width: 100%;
					margin: 0;
				}
			}
		}
	}

	.terminview-termine {
		margin: 1rem;

		.terminview-termin-button {
			width: 5rem;
			margin: 0.25rem;
			border-radius: .3125rem;
			border: 1px solid #888;

			.terminview-termin {
				display: inline-flex;
				height: 60px;

				span {
					margin: 0 .25rem;
					align-self: center;
					font-size: 1rem;
				}

				.ampel {
					width: 33%;
					margin: .25rem;
				}
			}
		}
	}

	.terminview-termin-active {
		background-color: $color-clientmsg-info;
	}

}

@media screen and (max-width: $breakpoint-mobile) {
	.terminview-options-row-buttons span button {
		padding: 0.2rem;
	}
}