.ButtonGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: .5rem;

    .ButtonGroup-img {
        display: flex;
        justify-content: center;

        img {
            max-width: 8rem;
            max-height: 8rem;
        }
    }

    button {
        margin: .35rem;
        max-width: 10rem;
        min-width: 10rem;
        min-height: 10rem;
        border: .13rem solid $color-primary;
        border-radius: .3125rem;

        &:hover {
            background-image: linear-gradient(45deg, rgba(222, 222, 222, .5), rgba(233, 233, 233, 0.5));
        }

        span {
            width: 100%;
            font-size: .9rem;
            color: $color-primary;
        }
    }
}

.ButtonGroupWrapper {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 320px) {

    .ButtonGroup button {
        min-width: 9rem;
        min-height: 9rem;
    }

}