.custom-dialog {
    width: 50%;
}

.custom-dialog .title {
    text-align: left;
    padding: 0;
}

@media screen and (max-width: 425px) {

    .custom-dialog {
        width: calc(100% - 1rem);
    }

}