@import '../variables';

.selection-header-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    vertical-align: middle;
    margin: 1rem 0;

    .selection {
        display: flex;
        margin: 0 .5rem;
        padding: 0 0.5rem;

        img {
            max-width: 12rem;
            max-height: 3.2rem;
            margin: 0.5625rem 0;
            align-self: center;
        }

        span {
            align-self: center;
            color: $color-primary;
            font-size: 1rem;
            font-weight: 500;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

    }

}

@media screen and (max-width: 425px) {
    .selection-header-view {
        margin: 0.5rem 0;
    }
}