.autoupdater {
  padding: 0.5em;
  background-color: $text-color-gray;
  width: 100%;
  z-index: $z-index-errorstatus;
  color: white;
  margin: auto;
  max-width: $content-max-width;

  ul {
      margin-block-start: 0.2em;
      margin-block-end: 0.2em;
  }

  &-main {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;

    &-symbol {
      margin-right: 0.5em
    }
    &-message {
      margin-right: 0.5em;
      align-self: center;
    }
  }

  a {
    color: white;
  }
  
  &-button {
    background-color: transparent;
    border-color: transparent;
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }  

  a:visited {
    color: white;
  }
}