@import '../variables';

.p-toast .p-toast-message.p-toast-message-warn {
    background: $color-background-light;
    border: solid $color-primary;
    border-width: 0 0 0 0.375rem;
    color: $color-custom-yellow-700;
    border-radius: 0.3125rem;

    .p-toast-icon-close {
        color: $color-primary;
    }

    .p-button-success {
        background-color: $color-primary;
        border: none;
        border-radius: 0.3125rem;
    }

    .p-button-secondary {
        border: none;
        border-radius: 0.3125rem;
    }

    p {
        color: $text-color-dark;
    }
}

.warn-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: $text-color-dark;
}

.warn-buttons {
    display: flex;
}

.warn-button {
    width: 50%;
}

.warn-button>button {
    width: calc(100% - 0.2rem);
}