@import 'variables';

.container-row-v {
    background-color: $color-background-light-gray;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .5rem;
    position: relative;
}

.column-v {
    padding: .5rem;
    width: 16%;
    line-height: 2rem;

    .column-title-v {
        padding: .5rem;
        text-align: left;
        font-weight: 500;
        font-size: 0.98rem;
        color: $color-primary;
        width: 100%;
    }

    .column-value-v {
        padding: .5rem;
        text-align: left;
        vertical-align: middle;
        width: 100%;
        min-height: 1rem;
    }
}

.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    overflow-wrap: anywhere;
}


@media screen and (max-width: 768px) {

    .container-row-v {
        padding: .5rem;
    }

    .column-v {
        width: 100%;
        padding: 0 .5rem;
        border-bottom: 0.0625rem solid #ccc;
        display: flex;

        .column-title-v {
            width: 50%;
            padding: 0 .5rem;
        }

        .column-value-v {
            width: 50%;
            padding: 0 .5rem;
        }
    }

    .column-v:last-child {
        border-bottom: none;
    }

}

@media screen and (max-width: 425px) {

    .column-v {
        display: block;

        .column-title-v {
            width: 100%;
        }

        .column-value-v {
            width: 100%;
        }
    }

}