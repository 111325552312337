.formbox-wrapper {

	.formbox {
		max-width: 768px;
		min-width: 425px;
		margin: 2rem auto;
		padding: 0.5rem;
		background-color: #dddddd;

		.p-dropdown {
			max-width: 700px;
			min-width: 280px;
			margin: 1rem 0;

			input {
				margin: 0.25rem;
			}
		}

		.p-dropdown-label {
			white-space: normal;
		}

		.formbox-field {
			padding: .5rem;

			label {
				margin: 1rem;
				padding: .5rem 0;
			}

			input {
				margin: 1rem;
			}

			button {
				margin: 0.25rem;
				background-color: $color-secundary;
				border-radius: 0.25rem;
				border-width: 0;
				color: #fff;
				font-size: 1rem;
				padding: 0.5rem 0.75rem;
				cursor: pointer;

				&:hover {
					background-color: $color-secundary-hover;
				}

				&:disabled {
					background-color: #a8a8a8;
					cursor: default;
				}
			}

			ul {
				text-align: left;
				display: inline-block;

				>div {
					margin: .5rem;

					.p-checkbox {
						margin: 0 .5rem;
					}
				}
			}

			.p-datatable {
				min-width: 80vw;
				padding: 0 1rem;
			}

			.input-row {
				display: flex;

				label {
					text-align: right;
					width: 60%;
				}

				.input-field-wrapper {
					width: 40%;
					text-align: left;
				}
			}
		}

		.formbox-field-label {
			display: flex;
			justify-content: center;
			padding: .5rem 0;

			label {
				min-width: 7rem;
				align-self: center;
				margin-right: 1rem;
				padding: .5rem 0;
			}

			input {
				min-width: 250px;
			}
		}

		.formbox-field-error {
			display: block;
			padding: .5rem;
			color: $text-color-error;
		}

		.formbox-field-warning {
			display: block;
			padding: .5rem;
			color: $text-color-warning;
		}
	}
}

@media screen and (max-width: 425px) {
	.formbox-wrapper {
		display: block;

		.formbox {
			min-width: 0;

			.formbox-field-label {
				display: inline-grid;
				width: 90%;
				justify-content: normal;
				padding: .5rem;

				label {
					width: 100%;
					text-align: left;
				}
			}
		}

		.formbox-field ul {
			padding-inline-start: .5rem;
		}
	}

}

@media screen and (max-width: 320px) {

	.formbox-wrapper .formbox-field .input-row label {
		margin: 1rem 0;
	}

}