// Components
@import 'variables';

.booking {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
	margin-bottom: 2rem;

	i {
		font-size: 2em;
	}

	div {
		margin-left: 1rem;

		font-size: 1.5em;
	}
}