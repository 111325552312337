.errorstatus {
  padding: 0.5em;
  background-color: $text-color-error;
  width: 100%;
  z-index: $z-index-errorstatus;
  color: white;
  margin: auto;
  max-width: $content-max-width;

  ul {
      margin-block-start: 0.2em;
      margin-block-end: 0.2em;
  }

  &-main {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    &-symbol {
      margin-right: 0.5em
    }

    &-time {
      margin-right: 0.5em
    }
  }

  &-close {
    &:hover {
      filter: brightness(80%);
    }
  }

  &-details {
    &-entry {
      display: flex;
      flex-wrap: nowrap;

      &-symbol {
        margin-right: 0.5em
      }
    }

    &-warn {
      background-color: $text-color-warning;
    }

    li {
      list-style: none;
      // border-radius: $border-radius-standard;
    }
  }
}