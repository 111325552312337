@import 'variables';

.errorview {
    &-Header {
        display: flex;
        justify-content: space-around;

        .errorview-logo {
            width: 35rem;

            @media (max-width: $content-max-width) {
                width: 66vw;
            }
        }
    }

    &-content {
        margin-bottom: 3em;
        display: flex;
        justify-content: space-around;

        div {
            font-size: large;
            text-align: center;

            .errorview-Link {
                display: flex;
                justify-content: space-around;
                text-decoration: none;

                button {

                    width: 10em;
                    height: 2em;
                }
            }

        }
    }
}