@import 'variables';

.language-flags {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0 1.5rem 0;
}

.language-flag {
    width: 10rem;
    padding: 0.5rem 0 1.5rem 0;
    cursor: pointer;
}

.language-flag>img {
    width: 65%;
    border: 0.0625rem solid $color-boarder-primary;
}

@media screen and (max-width: 425px) {

    .language-flags {
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem 0 1.5rem 0;
    }

    .language-flag {
        width: 50%;
        padding: 0.5rem 0;
    }

}