.header {
    margin: auto;
    padding-top: 1rem;
    width: 100%;
    max-width: $content-max-width;
    border-bottom: 0.125rem solid $color-primary;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header .header-buttons {
    display: flex;
    justify-content: flex-end;
}

.header-buttons button {
    background-color: #fff;
    border: 0;
    color: $color-primary;
    font-weight: 500;
}

.logo {
    max-width: 22rem;
    display: flex;
    justify-content: flex-end;
}

.logo-clickable {
    cursor: pointer;
}

.info-button {
    float: left;
    vertical-align: top;
    filter: invert(11%) sepia(67%) saturate(4002%) hue-rotate(203deg) brightness(96%) contrast(104%);
}

@media screen and (max-width: 425px) {

    .logo {
        width: calc(80% - 1rem);
        margin: auto;
    }

    .header {
        display: inherit;
    }

    .header .header-buttons {
        width: 100%;
        justify-content: center;
    }

}